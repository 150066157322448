<template>
    <div class="bo">
        <div class="top">
            <div>
                <img src="@/assets/img/Slice35@2x.png" />
                <span>编辑接送人</span>
            </div>
            <div>
                <img src="../../assets/img/de.png" alt="" @click="delparent()">
            </div>
        </div>
        <div class="name">
            <div class="name_top">
                <div class="ti"></div>
                <div>称呼</div>
            </div>
            <div>
                <input type="text" id="name" placeholder="请输入称呼" v-model="temp.user_name" />
            </div>
            <div>
                <div>接送人手机号</div>
            </div>
            <div>
                <input type="text" maxlength="11" id="num" placeholder="请输入手机号" v-model="temp.mobile" />
            </div>
        </div>

        <!-- <div class="time">
            <div class="name_top">
                <div class="ti"></div>
                <div>设置有效期</div>
            </div>
            <div>
                <div class="radio">
                    <span>长期有效</span>
                    <van-radio-group v-model="temp.radio">
                        <van-radio name="1" shape="square"></van-radio>
                    </van-radio-group>
                </div>
            </div>
            <div>
                <div class="date">
                    <div>
                        <span>自定义有效日期</span>
                        <van-radio-group v-model="temp.radio">
                            <van-radio name="2" shape="square"></van-radio>
                        </van-radio-group>
                    </div>

                    <div class="checktime">
                        <div @click="start_show = !start_show">{{ temp.start_time }}</div>
                        <div>-</div>
                        <div @click="end_show = !end_show">{{ temp.end_time }}</div>
                        <div><img src="../../assets/img/Slice59@2x.png" alt="" /></div>
                    </div>

                    <div class="datebtn">
                        <div @click="tody">今天</div>
                        <div @click="tomo">明天</div>
                        <div @click="week">本周</div>
                        <div @click="nextmonth">本月</div>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="photo">
            <div>
                <span>上传人脸照</span>
            </div>
            <div>
                <van-uploader v-model="file" multiple :max-count="1" :after-read="afterread" />
            </div>
            <div class="msg">
                <div class="left">
                    <div>
                        <img src="../../assets/img/Slice58@2x.png">
                        <span>标准人脸图展示</span>
                    </div>
                    <div>
                        <img src="../../assets/img/zhenjianzhao.png">
                    </div>
                </div>
                <div class="right">
                    <div>
                        <img src="../../assets/img/Slice158@2x.png">
                        <span>标准人脸图展示</span>
                    </div>
                    <div>
                        <img src="../../assets/img/zi.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="btn">
            <img src="../../assets/img/button_submit@2x.png" @click="submit">
        </div>

        <!-- <van-popup v-model="start_show" position="bottom" :style="{ height: '50%' }">
            <van-datetime-picker v-model="date1" type="date" title="选择开始时间" @confirm="start_confirm" :formatter="formatter"
                @change="start_change" :min-date="minDate" @cancel="start_cancel" />
        </van-popup>
        <van-popup v-model="end_show" position="bottom" :style="{ height: '50%' }">
            <van-datetime-picker v-model="date2" type="date" title="选择结束时间" @confirm="end_confirm" :formatter="formatter"
                @change="end_change" :min-date="minDate" @cancel="end_cancel" />
        </van-popup> -->
    </div>
</template>

<script>
import { upload, addpick, pickinfo, delparent } from '../../api/pickup/list.js'
import { Notify } from 'vant';
import { Dialog } from 'vant';
export default {
    data() {
        return {
            parent_id: this.$route.query.id,
            start_show: false,
            end_show: false,
            temp: {
                // radio: "1",
                // start_time: "开始时间",
                // end_time: "结束时间",
                user_name: '',
                mobile: '',
                face: '',
            },
            file: [],
            oss: process.env.VUE_APP_OSS_URL,
        };
    },
    created() {
        this.getinfo()
    },
    methods: {

        // 上传图片
        async afterread(file) {

            var f = await this.$api.compressImg(file.file)
            var formdata = new FormData()
            formdata.append('image', f)
            upload(formdata).then(res => {
                console.log(res);
                this.temp.face = res.data.url
            })
        },
        // 提交
        submit() {
            if (!this.temp.user_name) {
                Notify({ type: 'danger', message: '请输入家长称呼' });
                return;
            }
            if (!this.temp.mobile) {
                Notify({ type: 'danger', message: '请输入家长手机号' });
                return;
            }
            addpick(this.temp).then(res => {
                if (res.code == 200) {
                    Notify({ type: 'success', message: '编辑成功' });
                    setTimeout(() => { history.go(-1) })
                } else {
                    Notify({ type: 'success', message: res.msg });
                }
            })
        },
        // 获取接送人详情
        getinfo() {
            pickinfo(this.parent_id).then(res => {
                console.log(res);
                this.temp.user_name = res.data.user_name
                this.temp.mobile = res.data.mob
                this.temp.face = res.data.face

                this.file[0] = { url: this.oss + res.data.face }
                this.checkImg(this.file[0].url)
            })
        },
        // 删除接送人
        delparent() {

            Dialog.confirm({
                title: '删除',
                message: '这个操作不可逆，确定要删除吗',
            })
                .then(() => {
                    var obj={
                parent_id: this.parent_id
            }
            delparent(obj).then(res => {
                if(res.code == 200){
                    Notify({ type: 'success', message: '删除成功' });
                    history.go(-1)
                }else{
                    Notify({ type: 'error', message: res.msg });
                }
            })
                })
                .catch(() => {
                    console.log('12');
                });


            
        },


        checkImg(imgurl) {
            return new Promise(function (resolve, reject) {
                var imgObj = new Image();
                imgObj.src = imgurl;
                imgObj.onload = function (res) {
                    resolve(res);
                }
                imgObj.onerror = function (err) {
                    reject(err)
                }
            }).then(() => {
                console.log('有效链接')
            }).catch(() => {
                console.log('无效链接')
                this.file = [
                    { url: this.oss + 'kindergarten/photo/20230913/71fef046d925a0bc5369a0a97043c406c06bb8f7.png' }
                ]
            })
        },


    },
};
</script>

<style lang="less" scoped src="./css/edit.less"></style>
